import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab14b6ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contents-wrap flex justify-between items-center" }
const _hoisted_2 = {
  key: 0,
  class: "mr-5"
}
const _hoisted_3 = ["src", "srcset"]
const _hoisted_4 = { class: "solution-description" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["src", "srcset"]
const _hoisted_7 = { class: "text-2xl" }
const _hoisted_8 = { class: "text-3xl my-4" }
const _hoisted_9 = { class: "text-base mb-9" }
const _hoisted_10 = {
  key: 1,
  class: "ml-5"
}
const _hoisted_11 = ["src", "srcset"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isReverse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.imgName)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.imagePath,
                srcset: `${_ctx.imagePath}, ${_ctx.imagePath2X} 2x`,
                alt: "img-solution",
                class: "solution-image"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.iconPath,
          srcset: `${_ctx.iconPath}, ${_ctx.iconPath2x} 2x`,
          alt: "icon-solution",
          class: "mr-2"
        }, null, 8, _hoisted_6),
        _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.subTitle), 1)
      ]),
      _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.description), 1)
    ]),
    (!_ctx.isReverse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.imgName)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.imagePath,
                srcset: `${_ctx.imagePath}, ${_ctx.imagePath2X} 2x`,
                alt: "img-solution",
                class: "solution-image"
              }, null, 8, _hoisted_11))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}