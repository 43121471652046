const solutionEducation = [
  {
    subTitle: "1:1 전화 학습",
    title: "커넥트 라이브를 이용해 한계가 없는 전화 서비스를 만드세요",
    description:
      "교육시장이 빠르게 변화하면서, 다양한 학습자들은 시공간 제약 없는 교육 서비스가 필요합니다. 커넥트 라이브로 시간과 장소에 구애받지 않는 1:1 전화 학습 서비스를 제공할 수 있습니다. 전화 번호를 노출할 필요가 없어 안심할 수 있고, 국제 전화 대비 총 소유비용을 절감할 수 있습니다. 1:1 음성 통화 학습서비스, 1:1 영상 통화 학습 서비스로 활용할 수 있습니다.",
    icnName: "edu-1",
    imgName: "edu-1",
    url: "/products/livecall",
    isReverse: false
  },
  {
    subTitle: "1:N 실시간 강의",
    title: "커넥트 라이브로 모든 공간을 학습 공간으로 변화시키세요",
    description:
      "비대면 강의가 보편화 되면서, 원격 수업 시 원활한 의사소통이 중요해졌습니다. 커넥트 라이브로 현실의 생생함을 그대로 비대면 강의에 재현할 수 있습니다. 커넥트 라이브를 사용해 전세계에 실시간 강의 서비스를 배포해보세요. 기업 사내교육, 이러닝 서비스로 다양하게 활용할 수 있습니다.",
    icnName: "edu-2",
    imgName: "edu-2",
    url: "/products/livecast",
    isReverse: true
  },
  {
    subTitle: "비대면 토론 수업",
    title: "커넥트 라이브로 온라인 공간에서 제약없이 대화해보세요",
    description:
      "비대면 강의가 보편화 되면서, 학습자들이 원활한 의사소통을 할 수 있는 토론 수업 기능이 필요합니다. 커넥트 라이브로 비대면 상황에서 실시간으로 대화하고 상호작용하며 가상의 교육공간을 만들 수 있습니다. 커넥트 라이브로 자유롭고 빠르게 서비스를 배포하고 전세계를 무대로 대화해보세요.",
    icnName: "edu-3",
    imgName: "edu-3",
    url: "/products/liveconference",
    isReverse: false
  }
];

const solutionMedia = [
  {
    subTitle: "소셜 라이브",
    title: "커넥트 라이브를 이용해 새로운 소셜 라이브를 시작하세요",
    description:
      "소셜 네트워크는 계속해서 발전하고 있습니다. 사용자는 네트워크에 참여해 상호작용하고, 사회적 유대를 쌓을 수 있습니다. 커넥트 라이브로 사용자 참여를 이끌어내는 영상, 음성 소셜 라이브 서비스를 제공해보세요. 고민 상담, 온라인 노래방 서비스와 여행지, 버스킹 공연 영상 스트리밍 서비스 등 사람이 모이는 모든 플랫폼에 사용할 수 있습니다.",
    icnName: "med-1",
    imgName: "med-1",
    url: "/products/livecall",
    isReverse: false
  },
  {
    subTitle: "라이브 중계",
    title: "커넥트 라이브를 이용해 경계 없는 이벤트 중계를 제공하세요",
    description:
      "새롭고 다채로운 콘텐츠를 찾고 그 콘텐츠에 참여하길 원하는 사용자가 늘고 있습니다. 커넥트 라이브로 사용자가 경험하지 못한 콘텐츠를 제공하는 동시에, 참여를 이끌어낼 수 있습니다. 다양한 인터넷 기반 라이브 서비스를 제공하고 콘텐츠의 팬들과 상호작용해보세요. 스포츠 중계, 게임 중계, 실시간 증권방송 등의 다양한 분야에서 커넥트 라이브를 활용할 수 있습니다.",
    icnName: "med-2",
    imgName: "med-2",
    url: "/products/livecast",
    isReverse: true
  }
];

const solutionEnterprise = [
  {
    subTitle: "협업 커뮤니케이션",
    title: "커넥트 라이브로 비대면 업무를 수월하게 진행하세요",
    description:
      "재택 근무가 보편화된 지금, 원활한 소통이 가능한 협업 커뮤니케이션 도구가 필요합니다. 커넥트 라이브를 사용해 모든 공간에서 협업의 시너지를 이끌어낼 수 있습니다. 1초 미만의 지연시간(Latency)로 의사소통이 가능한 음성/영상 커뮤니케이션 서비스를 만들어보세요. 2인/그룹 음성 채팅, 2인/그룹 화상 통화, 온라인 회의 서비스에 사용할 수 있습니다.",
    icnName: "ent-1",
    imgName: "ent-1",
    url: "/products/liveconference",
    isReverse: false
  },
  {
    subTitle: "비대면 계약/채용",
    title: "커넥트 라이브로 새로운 업무 패러다임을 만드세요",
    description:
      "급변하고 있는 업무 환경, 트렌드에 맞는 안전하고 유연한 비대면 서비스가 필요합니다. 커넥트 라이브를 사용해 비대면 계약/채용을 위한 영상 서비스를 제작해보세요. 커넥트 라이브로 비즈니스를 전세계로 확장하고, 새로운 업무 패러다임을 구축할 수 있습니다. 원격 인터뷰, 화상 바이어 미팅 등 다양한 서비스로 활용할 수 있습니다.",
    icnName: "ent-2",
    imgName: "ent-2",
    url: "/products/liveconference",
    isReverse: true
  },
  {
    subTitle: "비대면 금융",
    title: "커넥트 라이브로 금융의 범위를 확장하세요",
    description:
      "비대면 커뮤니케이션이 보편화되며 고객은 시공간 제약없는 디지털 금융을 원하고 있습니다. 커넥트 라이브를 통해 오프라인 금융 서비스의 한계를 극복해보세요. 실시간 대화형 디지털 금융을 초기 비용에 대한 부담 없이 시작할 수 있습니다. 라이브 경매, 온라인 보험손해사정, 비대면 금융상품 상담 서비스로 사용할 수 있습니다.",
    icnName: "ent-3",
    imgName: "ent-3",
    url: "/products/liveconference",
    isReverse: false
  }
];

const solutionCommerce = [
  {
    subTitle: "자사몰 라이브스트리밍",
    title: "커넥트 라이브로 자사몰의 브랜드 경쟁력을 강화하세요",
    description:
      "라이브 스트리밍 서비스를 통해 소비자에게 새로운 고객 경험을 제공하고 동시에 자사몰의 브랜드 경쟁력을 높일 수 있습니다. 커넥트 라이브를 통해 소비자와 실시간으로 대화해보세요. 1초 미만의 Latency로 소비자에게 생생한 쇼핑 경험을 제공할 수 있습니다. 커넥트 라이브로 초기 비용에 대한 부담없이 자사몰에 라이브 스트리밍을 구축해보세요.",
    icnName: "com-1",
    imgName: "com-1",
    url: "/products/livecast",
    isReverse: false
  },
  {
    subTitle: "라이브 커머스 플랫폼",
    title: "커넥트 라이브로 이커머스에 새로운 고객 경험을 추가하세요",
    description:
      "커머스 플랫폼은 라이브 방송을 통해 소비자와 실시간으로 소통할 수 있습니다. 커넥트 라이브로 라이브 방송 서비스를 소비자에게 생생한 구매 경험을 제공해보세요. 1초 미만의 Latency로 오프라인 쇼핑 경험을 온라인에 그대로 구현할 수 있습니다. 쇼핑 라이브 방송, 인플루언서 쇼핑 라이브 등 다양한 라이브 커머스 서비스에 사용할 수 있습니다.",
    icnName: "com-2",
    imgName: "com-2",
    url: "/products/livecast",
    isReverse: true
  },
  {
    subTitle: "고객 서비스",
    title: "커넥트 라이브로 편리한 고객서비스를 제공하세요",
    description:
      "비대면 고객 서비스를 통해 공간에 구애받지 않고 직접 고객과 대면할 수 있습니다. 커넥트 라이브를 사용해 대면 상담의 생생함을 그대로 비대면 고객 서비스에 재현해보세요. 음성/영상 콜센터, 화상 고객상담 서비스 등 고객과의 접점이 필요한 모든 곳에서 커넥트 라이브를 사용할 수 있습니다.",
    icnName: "com-3",
    imgName: "com-3",
    url: "/products/livecall",
    isReverse: false
  }
];

const solutionHealthcare = [
  {
    subTitle: "비대면 심리상담",
    title: "커넥트 라이브로 안심할 수 있는 비대면 상담 서비스를 제공하세요",
    description:
      "심리 상담 서비스는 사용자들로 하여금 민감한 개인정보가 노출되는 걱정이 없어야 합니다. 커넥트 라이브로 전화번호 노출 없는 안심 심리상담 서비스를 제공할 수 있습니다. 공간 제약 없이 누구에게나 닿을 수 있는 비대면 심리상담 서비스를 제공해보세요. 초기 비용에 대한 부담과 개인정보 노출 리스크가 없는 심리상담 서비스를 시작해보세요.",
    icnName: "hea-1",
    imgName: "hea-1",
    url: "/products/livecall",
    isReverse: false
  },
  {
    subTitle: "홈트레이닝",
    title: "커넥트 라이브로 피트니스의 경계를 허무세요",
    description:
      "빠르게 증가하는 피트니스 사용자에게 시공간 제약이 없는 트레이닝 서비스를 제공해보세요. 커넥트 라이브를 사용해 기존 피트니스의 경계를 허물고 새로운 비즈니스를 제공할 수 있습니다. 라이브 스트리밍 초기 비용에 대한 부담 없이, 새로운 홈트레이닝 서비스를 시작해보세요. 비대면 마라톤, 원격 퍼스널 트레이닝(PT), 비대면 그룹 엑서사이즈(GX)에 사용할 수 있습니다.",
    icnName: "hea-2",
    imgName: "hea-2",
    url: "/products/livecast",
    isReverse: true
  },
  {
    subTitle: "원격 식단관리",
    title: "커넥트 라이브로 웰빙의 범위를 확장하세요",
    description:
      "건강과 식습관에 대한 사람들의 관심은 시간이 지날수록 높아지고 있습니다. 커넥트 라이브로 상호작용 가능한 원격 식단관리 서비스를 만들어보세요. 시공간 제약 없는 비대면 식단 관리로 사용자의 삶에 밀착된 서비스를 제공할 수 있습니다. 비대면 1:1 영양상담 서비스, 원격 식단관리 등 밀착 관리가 필요한 다양한 분야에 적용할 수 있습니다.",
    icnName: "hea-3",
    imgName: "hea-3",
    url: "/products/livecall",
    isReverse: false
  },
  {
    subTitle: "비대면 원격진료",
    title: "커넥트 라이브로 편리한 의료서비스를 제공하세요",
    description:
      "비대면 의료를 통해 공간에 구애받지 않는 새로운 의료 서비스를 구축할 수 있습니다. 커넥트 라이브를 통해 환자와 제약 없는 의사소통이 가능합니다. 비대면 서비스의 초기 비용 부담 없이 새로운 의료 서비스를 제공할 수 있습니다. 커넥트 라이브로 비대면 원격진료, 비대면 의료상담 서비스를 시작할 수 있습니다.",
    icnName: "hea-4",
    imgName: "hea-4",
    url: "/products/liveconference",
    isReverse: true
  }
];

export {
  solutionEducation,
  solutionMedia,
  solutionEnterprise,
  solutionCommerce,
  solutionHealthcare
};
