
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    imgName: {
      type: String,
      require: true
    }
  },
  setup(props) {
    const imgUrl = `url(/resource/images/img-solution-${props.imgName}.png)`;
    const imgUrl2x = `url(/resource/images/img-solution-${props.imgName}-2x.png)`;
    return {
      imgUrl,
      imgUrl2x
    };
  }
});
